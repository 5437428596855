<script setup>
import { provide } from 'vue';

/**
 * weight: "thin" | "light" | "regular" | "bold" | "fill" | "duotone"
 */
const props = defineProps({
  name: {
    type: Object,
    required: true,
  },
  color: {
    type: String,
  },
  size: {
    type: Number,
  },
  weight: {
    type: String,
    default: 'regular',
  },
});

provide('size', props.size);
</script>

<template>
  <Component :is="name" :weight="weight" :size="size" :color="color" />
</template>
