import { useAppStore } from '@/stores/app';

export default function storeGuard(to, from, next) {
  const appStore = useAppStore();

  appStore.$patch({
    page: {
      title: to.name == 'Character/Detail' ? to.params.name : to.meta.title,
      path: to.path,
      pathName: to.name,
    },
  });

  next();
}
