import { createApp, markRaw } from 'vue';
import { createPinia } from 'pinia';
import { createHead } from '@vueuse/head';

import '@/lib/tooltips.js';
import '~/styles/app.scss';

import App from '@/App.vue';
import router from '@/router';

if (import.meta.hot) {
  import.meta.hot.on('vite:beforeUpdate', () => console.clear());
}

const pinia = createPinia();

pinia.use(({ store }) => {
  store.$router = markRaw(router);
});

const app = createApp(App);

app.use(pinia).use(router).use(createHead()).mount('#app');
