export default {
  // app.js
  DEFAULT_APP_TITLE: 'Application Title',
  DEFAULT_APP_DESCRIPTION: 'My beautiful website',

  // auth.js
  AUTH_TOKEN_KEY_NAME: 'sotAuthToken',
  AUTH_EXPIRATION_KEY_NAME: 'sotAuthTokenExpiration',
  WL_TOKEN_KEY_NAME: 'sotWlToken',
};
