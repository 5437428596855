<script setup>
import { computed, onMounted } from 'vue';
import { useHead } from '@vueuse/head';
import { useBreakpoints } from '@vueuse/core';

import { mainNavigation } from '@/data/navigation';
import { useAppStore } from '@/stores/app';

const appStore = useAppStore();
const loaded = ref(false); // Track if content has loaded

const breakpoints = useBreakpoints({
  mobile: 320,
  tablet: 480,
  widetablet: 832,
  desktop: 1088,
  widescreen: 1344,
});

const appName = computed(() => appStore.appName);
const appTitle = computed(() => appStore.appTitle);
const appDescription = computed(() => appStore.appDescription);
const appToastTimeout = computed(() => appStore.appToastTimeout);

useHead({
  title: appTitle,
  meta: [{ name: 'description', content: appDescription }],
});

onMounted(() => {
  appStore.isMobile = breakpoints.between('mobile', 'tablet').value;
  appStore.isTablet = breakpoints.between('tablet', 'widetablet').value;
});
</script>

<template>
  <AppHeader :title="appName" :subtitle="appDescription" />
  <AppNavigation :urls="mainNavigation" />
  <AppMain>
    <RouterView />
  </AppMain>
  <AppFooter />
</template>

<style lang="scss">
html {
  @include mix.font(primary);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.015rem;
  line-height: 1;
  overscroll-behavior-y: none;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  touch-action: manipulation;
  -webkit-user-drag: none;
  word-wrap: break-word;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: 100%;
  overflow: hidden;
  position: relative;
  height: 100%;
}

body {
  background-color: var.color(black);
  height: 100%;
  overflow-y: auto;
  position: relative;
  scroll-behavior: smooth;

  @include mix.media(widetablet) {
    scrollbar-gutter: stable both-edges;
  }

  &::before {
    animation: backgroundOpacity 2s linear 1s forwards;
    background: url(~/images/background.webp) repeat-y center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    content: '';
    inset: 0;
    mix-blend-mode: screen;
    position: fixed;
  }

  @keyframes backgroundOpacity {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.5;
    }
  }
}

.App {
  animation: appOpacity 2s linear 1s forwards;
  align-items: center;
  color: var.color(secondary, 30);
  display: flex;
  flex-direction: column;
  grid-gap: fn.size(16px);
  min-height: 100vh;
  opacity: 0;

  @include mix.media(widetablet) {
    grid-gap: fn.size(32px);
  }

  @keyframes appOpacity {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
</style>
