<script setup>
const props = defineProps({
  type: {
    type: String,
  },
  url: {
    type: String,
    required: true,
  },
  icon: {
    type: Object,
  },
  text: {
    type: String,
    required: true,
  },
  method: {
    type: String,
  },
});

const isActive = ref(false);
</script>

<template>
  <li class="AppNavigation__Item">
    <RouterLink :to="url" :method="method" class="AppNavigation__Item__Link">
      <VPIcon
        v-if="icon"
        :name="icon"
        weight="duotone"
        :size="24"
        class="AppNavigation__Item__Link__Icon"
      />
      <span class="AppNavigation__Item__Link__Text">{{ text }}</span>
    </RouterLink>
  </li>
</template>
<style lang="scss">
.AppNavigation {
  &__Item {
    &__Link {
      @include mix.corner($size: fn.size(40px));
      @include mix.transition;

      align-items: center;
      background-color: transparent;
      color: var.color(primary);
      display: inline-flex;
      grid-gap: fn.size(4px);
      font-size: fn.size(12px);
      font-weight: var.font-weight(bold);
      justify-items: center;
      padding: fn.size(8px) fn.size(16px);
      position: relative;
      text-decoration: none;

      &:hover {
        box-shadow: 0 0 0 fn.size(4px) var.color(primary, 40a);
      }

      &:hover,
      &:focus-within,
      &--Active {
        background-color: var.color(primary, 40);
        color: var.color(primary, 90);
      }

      &--Logout {
        color: var.color(error, 40);

        &:hover,
        &:focus-within {
          background-color: var.color(error, 40);
          box-shadow: 0 0 0 fn.size(4px) var.color(error, 40a);
          color: var.color(error, 90);
        }
      }

      &__Text {
        display: none;
      }

      @include mix.media(widetablet) {
        &__Text {
          display: inline-block;
        }
      }
    }
  }
}
</style>
