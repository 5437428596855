import { defineStore, acceptHMRUpdate } from 'pinia';
import { useLocalStorage } from '@vueuse/core';

import text from '@/stores/_text';
import config from '@/config';

export const useAppStore = defineStore(
  {
    id: 'app',

    state: () => ({
      page: {
        title: '',
        path: '',
        pathName: '',
      },
      pageTransition: {
        name: 'router-view',
        mode: 'in-out',
      },
      isSectionTitleVisible: true,
      showBackButton: false,
      isMobile: false,
      isTablet: false,
      toastTimeout: 1000,
      showModel: useLocalStorage('app.showModel', true),
    }),

    getters: {
      appName() {
        return import.meta.env.VITE_APP_NAME;
      },
      appTitle(state) {
        return state.page.title + ' | ' + this.appName || text.DEFAULT_APP_TITLE;
      },
      appDescription() {
        return import.meta.env.VITE_APP_DESCRIPTION || text.DEFAULT_APP_DESCRIPTION;
      },
      appToastTimeout(state) {
        return config.NOTIFICATION_TIMEOUT || state.toastTimeout;
      },
      paginationPerPage(state) {
        return state.isMobile ? 10 : 25;
      },
    },

    actions: {
      setPageTransition(value) {
        if ('default' === value) {
          this.pageTransition.name = 'router-view';
          this.pageTransition.mode = 'in-out';
        }

        if ('back' === value) {
          this.pageTransition.name = 'router-view-back';
          this.pageTransition.mode = '';
        }
      },
      switchModel() {
        this.showModel = !this.showModel;
      },
    },
  },
  {
    persist: true,
  }
);

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot));
}
