<script setup>
const currentYear = new Date().getFullYear();
</script>

<template>
  <footer class="AppFooter">
    <div class="AppFooter__Copyright">
      Stalkers of Time &copy; {{ currentYear }} | All Right Reserved
    </div>
  </footer>
</template>

<style lang="scss">
.AppFooter {
  padding-block: fn.size(4px);

  &__Copyright {
    color: var.color(secondary, 40);
    font-size: fn.size(12px);
  }
}
</style>
